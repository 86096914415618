import React, { useEffect, useState } from 'react';

interface TimerProps {
  startTime?: Date,
  stopTime?: Date
}

function formatElapsed(ms: number) {
  return (Math.round(ms / 100) / 10).toFixed(1);
}

function Timer ({ startTime, stopTime }: TimerProps) {
  const [elapsed, setElapsed] = useState(0);

  useEffect(() => {
    let intervalId: number | undefined = undefined;
    if (startTime) {
      if (!stopTime) {
        intervalId = window.setInterval(() => {
          const now = new Date().getTime();
          setElapsed(now - startTime.getTime());
        }, 50);
      } else {
        setElapsed(stopTime.getTime() - startTime.getTime());
      }
    } else {
      setElapsed(0);
    }

    return () => clearInterval(intervalId)
  }, [startTime, stopTime]);

  return (
    <>
      <div><strong>Time</strong></div>
      <div className="TimerElapsed">{formatElapsed(elapsed)}</div>
    </>
  );
}

export default Timer;
